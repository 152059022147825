@font-face {
  font-family: "caros_softregular";
  src: url("../public/css/fonts/CarosSoft.woff2") format("woff2"),
    url("../public/css/fonts/CarosSoft.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "caros_softbold";
  src: url("../public/css/fonts/carossoftbold.woff2") format("woff2"),
    url("../public/css/fonts/carossoftbold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "caros_softmedium";
  src: url("../public/css/fonts/carossoftmedium.woff2") format("woff2"),
    url("../public/css/fonts/carossoftmedium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}